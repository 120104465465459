import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

interface CtaSectionProps {
  image: JSX.Element;
  headline: string;
  btnText: string;
}

const CtaSection: React.FC<CtaSectionProps> = ({
  image,
  headline,
  btnText,
  children,
}) => {
  return (
    <section className="relative py-0 max-h-96 h-screen xl:h-full xl:max-h-[42rem] overflow-hidden">
      {image}
      <div className="absolute inset-0 bg-black opacity-60 z-20" />
      <div className="container absolute inset-center z-30">
        <div className="flex justify-center">
          <div className="w-full max-w-screen-md text-center text-white">
            <h2 className="text-2xl md:text-3xl text-white font-bold mb-2">
              {headline}
            </h2>
            <p className="mb-4 md:text-lg mx-auto">{children}</p>
            <Button
              component={Link}
              to="/contact/"
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              className="rounded-none text-white "
            >
              {btnText}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
