import {
  EmailOutlined,
  KeyboardArrowDown,
  LocationOnOutlined,
  PhoneAndroidOutlined,
} from "@material-ui/icons";
import { graphql } from "gatsby";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactForm from "../components/ui/Contact/ContactForm/ContactForm";
import ContactInfoItem from "../components/ui/Contact/ContactInfo/ContactInfoItem";
import CtaSection from "../components/ui/Cta/Cta";
import ProjectGallery from "../components/ui/ProjectGallery/ProjectGallery";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";
import ServiceCard from "../components/ui/Services/ServiceCard";
import { formatPhoneNumber } from "../utils/formatPhoneNumber";

import { ContactInfo } from "../fragments/contactInfoFields";
import { HomepageData } from "../fragments/homepageFields";

interface HomepageProps {
  data: {
    prismicHomepage: HomepageData;
    prismicContactInfo: ContactInfo;
  };
  location: Location;
}

const Homepage: React.FC<HomepageProps> = ({ data, location }) => {
  const homepageData = data.prismicHomepage.data;
  const contactInfo = data.prismicContactInfo.data;

  return (
    <Layout>
      <SEO
        title={homepageData.seo[0].seo_title}
        description={homepageData.seo[0].seo_description}
        slug={location.pathname}
      />

      {homepageData.body.map((slice, index) => {
        if (slice.__typename === "PrismicHomepageBodyTopSection") {
          return (
            <section key={slice.__typename} className="relative p-0 clip-path">
              <div className="absolute inset-0 z-10 w-full h-full bg-black bg-opacity-50 " />

              <div>
                <GatsbyImage
                  image={
                    slice.primary.main_image.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={slice.primary.main_image.alt || ""}
                  className="md:h-[80vh] h-[70vh] min-h-[520px] z-0"
                />
              </div>
              <div className="absolute left-[50%] top-[50%] transform md:translate-y-[-70%] translate-y-[-65%] translate-x-[-50%] sm:text-center z-20 container text-4xl ">
                <h2 className="sm:text-xl text-lg font-medium text-white mb-2 max-w-xs sm:max-w-none">
                  {slice.primary.sub_headline.text}
                </h2>
                <h1 className="sm:text-5xl text-3xl text-white max-w-screen-sm mx-auto mb-0">
                  {slice.primary.headline.text}
                </h1>
              </div>
              <div className="absolute z-20 w-full text-center text-white md:bottom-28 bottom-16 ">
                <a href="#about">
                  <KeyboardArrowDown
                    className="relative transform transition-transform hover:translate-y-1"
                    fontSize="large"
                  />
                </a>
              </div>
            </section>
          );
        } else if (slice.__typename === "PrismicHomepageBodyFeatureSection") {
          return (
            <section
              key={`${slice.__typename}-${index}`}
              id={index === 1 ? "about" : "benefits"}
            >
              <div className="container">
                <div
                  className={`flex flex-wrap ${
                    slice.primary.image_position === "right"
                      ? "lg:flex-row-reverse"
                      : ""
                  } items-center lg:justify-between max-w-2xl mx-auto lg:max-w-none`}
                >
                  <div className="lg:w-6/12 w-full max-w-screen-sm mb-5 lg:mb-0">
                    <GatsbyImage
                      image={
                        slice.primary.image.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={slice.primary.image.alt || ""}
                    />
                  </div>
                  <div className="w-full lg:w-5/12 max-w-screen-sm">
                    <SectionIntro
                      subHeading={slice.primary.sub_heading.text}
                      heading={slice.primary.heading.text}
                    />
                    {slice.primary.description.raw.map((item, textIndex) => (
                      <p
                        key={`${slice.__typename}-text-${textIndex}`}
                        className="mb-3 last:mb-0"
                      >
                        {item.text}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          );
        } else if (slice.__typename === "PrismicHomepageBodyServices") {
          return (
            <section key={slice.__typename} className="bg-gray-50">
              <div className="container">
                <div className="grid sm:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-6 justify-items-center">
                  <div className="col-span-full">
                    <SectionIntro
                      subHeading={slice.primary.section_intro_sub_heading.text}
                      heading={slice.primary.section_intro_heading.text}
                      className="text-center mb-4"
                    ></SectionIntro>
                  </div>

                  {slice.items.map((service, index) => (
                    <ServiceCard
                      key={`${service.related_service.document.uid}-${index}`}
                      to={`/services/${service.related_service.document.uid}/`}
                      title={service.service_name.text}
                      image={
                        service.image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={service.image.alt || service.service_name.text}
                    >
                      {service.service_description.text}
                    </ServiceCard>
                  ))}
                </div>
              </div>
            </section>
          );
        } else if (slice.__typename === "PrismicHomepageBodyCta") {
          return (
            <CtaSection
              key={slice.__typename}
              image={
                <GatsbyImage
                  image={
                    slice.primary.background_image.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={slice.primary.background_image.alt || ""}
                  className="h-full max-h-96 xl:h-full xl:max-h-[24rem] z-10"
                />
              }
              headline={slice.primary.headine.text}
              btnText={slice.primary.button_text}
            >
              {slice.primary.description.text}
            </CtaSection>
          );
        } else if (slice.__typename === "PrismicHomepageBodyProjectsGallery") {
          return (
            <section key={slice.__typename}>
              <div className="container">
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 ">
                  <SectionIntro
                    subHeading={slice.primary.section_intro_sub_heading.text}
                    heading={slice.primary.section_intro_heading.text}
                    className="col-span-full text-center mb-6 justify-self-center"
                  >
                    <p className="max-w-lg mx-auto">
                      {slice.primary.section_intro_description.text}
                    </p>
                  </SectionIntro>
                  <ProjectGallery>
                    {slice.items.map((item, index) => (
                      <div key={`portfolio item-${index}`} className="mb-4">
                        <GatsbyImage
                          image={
                            item.image.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={item.image.alt || ""}
                        />
                      </div>
                    ))}
                  </ProjectGallery>
                </div>
              </div>
            </section>
          );
        } else return null;
      })}

      <section className="bg-gray-50">
        <div className="container">
          <div className="flex flex-wrap justify-between">
            <div className="sm:w-4/12 w-full mb-6 sm:mb-0">
              <h3 className="sm:text-3xl text-2xl">Contact Us</h3>
              <ul>
                <ContactInfoItem icon={LocationOnOutlined} title="Address">
                  <li>{contactInfo.address[0].street}</li>
                  <li>
                    <span className="mr-1">{contactInfo.address[0].city}</span>
                    <span>{contactInfo.address[0].zip_code}</span>
                  </li>
                  <li>{contactInfo.address[0].state}</li>
                </ContactInfoItem>
                <ContactInfoItem icon={EmailOutlined} title="Email">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`mailto:${contactInfo.email}`}
                    >
                      {contactInfo.email}
                    </a>
                  </li>
                </ContactInfoItem>
                <ContactInfoItem icon={PhoneAndroidOutlined} title="Phone">
                  <li>
                    <a
                      className="hover:text-primary-main"
                      href={`tel:${contactInfo.phone_number}`}
                    >
                      {formatPhoneNumber(contactInfo.phone_number)}
                    </a>
                  </li>
                </ContactInfoItem>
              </ul>
            </div>
            <div className="lg:w-5/12 sm:w-6/12 w-full">
              <h3 className="sm:text-3xl text-2xl">Send us a message</h3>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query HomepageQuery {
    prismicHomepage {
      id
      data {
        title {
          text
        }
        seo {
          seo_title
          seo_description
        }
        body {
          __typename
          ... on PrismicHomepageBodyTopSection {
            ...homepageTopSectionFields
          }
          ... on PrismicHomepageBodyFeatureSection {
            ...homepageFeatureFields
          }
          ... on PrismicHomepageBodyServices {
            ...homepageServicesFields
          }
          ... on PrismicHomepageBodyCta {
            ...homepageCtaFields
          }
          ... on PrismicHomepageBodyProjectsGallery {
            ...homepageProjectGalleryFields
          }
        }
      }
    }
    prismicContactInfo {
      ...contactInfoFields
    }
  }
`;
